import { Box, Button, Divider, Grid } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import React, { memo, useState } from 'react';
import SearchRow from './SearchRow/SearchRow';
import { defaultSearchStateValues } from '../../../constants/publicationManager';

const PublicationManagerSearch = ({ searchState, onSearch, publications, loading }) => {
  const [localSearchState, setLocalSearchState] = useState(searchState);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSearch(localSearchState);
      }}
    >
      <Grid container direction="column" spacing={2} alignItems="stretch">
        <Grid item>Keresés</Grid>
        {localSearchState?.map((rowState, i) => (
          <Grid item key={i}>
            <SearchRow
              searchParam={rowState.searchParam}
              setSearchParam={(value) =>
                setLocalSearchState((prevSearchState) => {
                  const newSearchState = [...prevSearchState];
                  newSearchState[i] = {
                    ...newSearchState[i],
                    searchParam: value,
                  };
                  return newSearchState;
                })
              }
              searchValue={rowState.searchValue}
              setSearchValue={(value) =>
                setLocalSearchState((prevSearchState) => {
                  const newSearchState = [...prevSearchState];
                  newSearchState[i] = {
                    ...newSearchState[i],
                    searchValue: value,
                    normalizedSearchValue:
                      rowState.searchParam?.normalize?.(value) ?? value,
                  };
                  return newSearchState;
                })
              }
              deleteRow={() =>
                setLocalSearchState((prevSearchState) => {
                  const newSearchState = [...prevSearchState];
                  newSearchState.splice(i, 1);
                  return newSearchState;
                })
              }
              publications={publications}
              loading={loading}
            />
          </Grid>
        ))}
        <Grid item>
          <Grid container alignItems="center" spacing={2} style={{ textAlign: 'center' }}>
            <Grid item xs={12} sm="auto">
              <Button
                size="small"
                color="primary"
                startIcon={<AddCircleIcon />}
                onClick={() =>
                  setLocalSearchState((prevSearchStates) => [
                    ...prevSearchStates,
                    {
                      searchParam: null,
                      searchValue: null,
                    },
                  ])
                }
              >
                Új sor hozzáadása
              </Button>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button
                size="small"
                color="secondary"
                startIcon={<RotateLeftIcon />}
                onClick={() => setLocalSearchState(defaultSearchStateValues)}
              >
                Visszaállítás
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Box display={{ xs: 'block', md: 'none' }}>
          <Divider style={{ color: 'red' }} />
        </Box>
        <Grid item>
          <Button fullWidth color="primary" startIcon={<SearchIcon />} type="submit">
            Keresés
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default memo(PublicationManagerSearch);
