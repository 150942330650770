import Document from 'flexsearch/src/document';
import { isEqual } from 'lodash';
import { useMemo, useRef } from 'react';

export const useSearchIndex = ({ searchDocs, options }) => {
  const optionsRef = useRef(options);

  if (!isEqual(options, optionsRef.current)) {
    optionsRef.current = options;
  }

  const searchIndex = useMemo(() => {
    const newIndex = new Document({
      tokenize: 'full',
      ...optionsRef.current,
    });

    // eslint-disable-next-line no-unused-expressions
    searchDocs?.forEach((doc) => newIndex.add(doc));

    return newIndex;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDocs, optionsRef.current]);

  return { searchIndex };
};
