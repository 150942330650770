import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Pagination from 'material-ui-flat-pagination';
import React, { memo, useCallback } from 'react';
import tableStyles from '../../HoldingsTable/HoldingsTable.module.scss';
import moment from 'moment';
import HeaderCell from './HeaderCell';
import { useReactiveVar } from '@apollo/client';
import { accessTokenVar } from '../../../apollo/localState/accessTokenVar';
import BodyCell from './BodyCell';

const PublicationManagerTable = ({
  page,
  itemsPerPage,
  onPageChange,
  publications,
  loading,
  orderState,
  onOrder,
}) => {
  const { accessToken } = useReactiveVar(accessTokenVar);
  // const emailColumnVisible = !!accessToken;
  // OKPD-12751
  const emailColumnVisible = true;

  const renderContent = useCallback(() => {
    const colSpan = emailColumnVisible ? 9 : 8;

    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={colSpan} style={{ textAlign: 'center' }}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    }

    const from = page * itemsPerPage;
    const to = (page + 1) * itemsPerPage;
    const publicationsToRender = publications?.slice(from, to);
    if (!publicationsToRender || publicationsToRender.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={colSpan} style={{ textAlign: 'center' }}>
            Nincs adat
          </TableCell>
        </TableRow>
      );
    }

    return publicationsToRender?.map((publication) => (
      <TableRow key={publication.guid}>
        <BodyCell>{publication.title}</BodyCell>
        {/* <BodyCell>{publication.subTitle}</BodyCell> */}
        <BodyCell>{publication.author}</BodyCell>
        <BodyCell>{publication.isn}</BodyCell>
        <BodyCell>{publication.releaseDate}</BodyCell>
        <BodyCell>{publication.partnerName}</BodyCell>
        {emailColumnVisible && <BodyCell>{publication.partnerEmail}</BodyCell>}
        <BodyCell>{publication.partnerCounty}</BodyCell>
        <BodyCell>{publication.partnerCity}</BodyCell>
        <BodyCell>
          {publication.processedAt
            ? moment(publication.processedAt).format('YYYY.MM.DD.')
            : ''}
        </BodyCell>
      </TableRow>
    ));
  }, [emailColumnVisible, loading, page, itemsPerPage, publications]);

  return (
    <Box
      className={[tableStyles.tableContainer].join(' ')}
      style={{
        maxHeight: 'none !important',
        width: '100%',
        display: 'table',
        tableLayout: 'fixed',
      }}
    >
      <TableContainer
        className={[tableStyles.instanceListTableContainer].join(' ')}
        style={{ maxHeight: 'none', marginTop: 0 }}
      >
        <Table
          stickyHeader
          className={[tableStyles.instanceListTable, tableStyles.openTable].join(' ')}
          style={{ tableLayout: 'fixed' }}
        >
          <TableHead>
            <TableRow>
              <HeaderCell
                id="title"
                orderState={orderState}
                onOrder={onOrder}
                style={{ width: '150px' }}
              >
                Cím
              </HeaderCell>
              {/* <HeaderCell id="subTitle" orderState={orderState} onOrder={onOrder}>
                Alcím
              </HeaderCell> */}
              <HeaderCell
                id="author"
                orderState={orderState}
                onOrder={onOrder}
                style={{ width: '65px' }}
              >
                Szerző
              </HeaderCell>
              <HeaderCell
                id="isn"
                orderState={orderState}
                onOrder={onOrder}
                style={{ width: '115px' }}
              >
                ISBN
              </HeaderCell>
              <HeaderCell
                id="releaseDate"
                orderState={orderState}
                onOrder={onOrder}
                style={{ width: '75px' }}
              >
                Megjelenés éve
              </HeaderCell>
              <HeaderCell
                id="partnerName"
                orderState={orderState}
                onOrder={onOrder}
                style={{ width: '125px' }}
              >
                Kiadó
              </HeaderCell>
              {emailColumnVisible && (
                <HeaderCell
                  id="partnerEmail"
                  orderState={orderState}
                  onOrder={onOrder}
                  style={{ width: '150px' }}
                >
                  Kiadó e-mail-címe
                </HeaderCell>
              )}
              <HeaderCell
                id="partnerCounty"
                orderState={orderState}
                onOrder={onOrder}
                style={{ width: '95px' }}
              >
                Megjelenés helye (vármegye)
              </HeaderCell>
              <HeaderCell
                id="partnerCity"
                orderState={orderState}
                onOrder={onOrder}
                style={{ width: '95px' }}
              >
                Megjelenés helye (település)
              </HeaderCell>
              <HeaderCell
                id="processedAt"
                orderState={orderState}
                onOrder={onOrder}
                style={{ width: '75px' }}
              >
                Beérkezés dátuma
              </HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderContent()}</TableBody>
        </Table>
      </TableContainer>
      <Grid container justifyContent="center">
        <Grid item>
          <Pagination
            offset={page * itemsPerPage}
            limit={itemsPerPage}
            total={publications?.length}
            onClick={(e, offset) => onPageChange(offset / itemsPerPage)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(PublicationManagerTable);
