/**
 * @enum {{ value: string; label: string; type: string; normalize?: Function }}
 */
export const searchParamOption = {
  title: {
    value: 'title',
    label: 'Cím',
    type: 'textField',
  },
  // subTitle: {
  //   value: 'subTitle',
  //   label: 'Alcím',
  //   type: 'textField',
  // },
  author: {
    value: 'author',
    label: 'Szerző',
    type: 'textField',
  },
  isn: {
    value: 'isnNormalized',
    label: 'ISBN',
    type: 'textField',
    normalize: (value) => value?.split(' ')?.join('')?.split('-')?.join(''),
  },
  releaseDate: {
    value: 'releaseDate',
    label: 'Megjelenés éve',
    type: 'textField',
  },
  partnerName: {
    value: 'partnerName',
    label: 'Kiadó',
    type: 'textField',
  },
  // partnerEmail: {
  //   value: 'partnerEmail',
  //   label: 'Kiadó e-mail-címe',
  //   type: 'textField',
  // },
  partnerCounty: {
    value: 'partnerCounty',
    label: 'Megjelenés helye (vármegye)',
    type: 'autocomplete',
  },
  partnerCity: {
    value: 'partnerCity',
    label: 'Megjelenés helye (település)',
    type: 'autocomplete',
  },
  processedAt: {
    value: 'processedAt',
    label: 'Beérkezés dátuma',
    type: 'dateRangePicker',
  },
};

export const defaultSearchStateValues = [
  {
    searchParam: searchParamOption.partnerCounty,
    searchValue: '',
  },
  {
    searchParam: searchParamOption.title,
    searchValue: '',
  },
  {
    searchParam: searchParamOption.processedAt,
    searchValue: {
      from: null,
      to: null,
    },
  },
];

export const defaultOrderStateValues = {
  orderBy: 'processedAt',
  order: 'desc',
};
