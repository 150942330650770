import React, { useCallback, useMemo, useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Grid, MenuItem, Select, useTheme } from '@material-ui/core';
import { encode } from 'flexsearch/src/lang/latin/simple';
import { useSearchIndex } from '../../hooks/useSearchIndex';
import {
  FETCH_PROTECTED_SUBMISSION_REPORT,
  FETCH_PUBLIC_SUBMISSION_REPORT,
} from '../../apollo/queries/submissionReport';
import PublicationManagerTable from './PublicationManagerTable/PublicationManagerTable';
import PublicationManagerSearch from './PublicationManagerSearch/PublicationManagerSearch';
import {
  defaultOrderStateValues,
  defaultSearchStateValues,
} from '../../constants/publicationManager';
import PublicationsExportButton from './PublicationManagerSearch/PublicationsExportButton/PublicationsExportButton';
import { usePublications } from '../../hooks/usePublications';
import { accessTokenVar } from '../../apollo/localState/accessTokenVar';
import { uniqBy } from 'lodash';
import sort from 'fast-sort';

// Kiadványkezelő AKA könyvtárosoknak AKA kötelespéldány-szolgáltatás
const PublicationManager = () => {
  const { accessToken } = useReactiveVar(accessTokenVar);

  const { data, loading } = useQuery(
    accessToken ? FETCH_PROTECTED_SUBMISSION_REPORT : FETCH_PUBLIC_SUBMISSION_REPORT
  );

  // Gyakorlatilag ez megfelel egy backend oldali keresésnek, így pl. a sorba rendezés és dátum formázás ennek nem része
  const searchDocs = useMemo(() => {
    if (data?.submissionReport2023 || data?.submissionReport2024) {
      const newSearchDocs = (data.submissionReport2023 ?? [])
        ?.concat(data?.submissionReport2024)
        ?.reduce((result, item) => {
          const newItem = {
            ...item,
            guid: item.guid ?? item.g ?? '',
            title: item.title ?? item.t ?? '',
            subTitle: item.subTitle ?? item.s ?? '',
            author: item.author ?? item.a ?? '',
            isn: item.isn ?? item.i ?? '',
            releaseDate: item.releaseDate ?? item.r ?? '',
            partnerName: item.partnerName ?? item.n ?? '',
            partnerCity: item.partnerCity ?? item.c ?? '',
            partnerCounty: item.partnerCounty ?? item.o ?? '',
            partnerEmail: item.partnerEmail ?? item.e ?? '',
            processedAt: item.processedAt ?? item.p ?? '',
          };

          newItem.isnNormalized = newItem.isn?.split(' ')?.join('')?.split('-')?.join('');

          Object.keys(newItem).forEach((key) => {
            newItem[key] = newItem[key]?.trim();
          });

          // 🚨 Danger zone: ezeket el kell felejteni miután OSZK oldalon javítják a rekordokat
          // OKPD-12081
          if (['0', '?', 'b', '-'].includes(newItem.partnerCounty)) {
            return result;
          }

          if (newItem.partnerCounty === 'Pest megye') {
            newItem.partnerCounty = 'Pest';
          }

          if (newItem.partnerCounty?.toLowerCase()?.includes('nincs adat')) {
            newItem.partnerCounty = 'Megjelenési hely nélkül';
          }

          // OKPD-12082
          if (['0', '?'].includes(newItem.partnerCity)) {
            return result;
          }

          if (newItem.partnerCity?.toLowerCase()?.includes('nincs adat')) {
            newItem.partnerCity = 'Megjelenési hely nélkül';
          }

          // OKPD-12083
          if (newItem.author?.toLowerCase()?.includes('nincs adat')) {
            newItem.author = '';
          }

          if (!newItem.releaseDate) {
            newItem.releaseDate = 'Megjelenési év nélkül';
          }

          if (!newItem.partnerName) {
            newItem.partnerName = 'Kiadó nélkül';
          }

          // OKPD-12116
          if (newItem.partnerName === 'KIADÓ NÉLKÜL') {
            newItem.partnerName = 'Kiadó nélkül';
          }

          // OKPD-12442
          if (newItem.partnerCounty === 'Budapest') {
            newItem.partnerCounty = 'Pest';
          }

          // 🚨

          result.push(newItem);

          return result;
        }, []);

      // OKPD-12430
      const deduplicatedNewSearchDocs = uniqBy(
        sort(newSearchDocs).desc((doc) => doc.processedAt),
        (doc) => doc.guid
      );
      console.log(
        `Removed ${
          newSearchDocs.length - deduplicatedNewSearchDocs.length
        } duplicates from searchDocs`
      );

      return deduplicatedNewSearchDocs;
    }

    return [];
  }, [data]);

  const { searchIndex } = useSearchIndex({
    searchDocs: searchDocs,
    options: {
      tokenize: 'forward',
      encode,
      document: {
        id: 'guid',
        index: [
          { field: 'title' },
          // { field: 'subTitle' },
          { field: 'author' },
          { field: 'isnNormalized' },
          { field: 'releaseDate' },
          { field: 'partnerName' },
          // { field: 'partnerEmail' },
          { field: 'partnerCounty', tokenize: 'strict' },
          { field: 'partnerCity', tokenize: 'strict' },
        ],
      },
    },
  });

  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchState, setSearchState] = useState(defaultSearchStateValues);
  const [orderState, setOrderState] = useState(defaultOrderStateValues);

  // Ez lenne a válasz amit a backend ad vissza a keresés eredményeképp, így pl. a sorba rendezést itt célszerű elvégezni
  const { publications } = usePublications({
    searchDocs,
    searchIndex,
    searchState,
    orderState,
  });

  const onPageChange = useCallback((value) => {
    setPage(value);
  }, []);

  const onItemsPerPageChange = useCallback((value) => {
    setItemsPerPage(value);
    setPage(0);
  }, []);

  const onSearch = useCallback((value) => {
    setSearchState(value);
    setPage(0);
  }, []);

  const onOrder = useCallback((value) => {
    setOrderState((prevOrderState) => ({
      orderBy: value,
      order:
        prevOrderState.orderBy === value && prevOrderState.order === 'asc'
          ? 'desc'
          : 'asc',
    }));
  }, []);

  const theme = useTheme();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item style={{ borderBottom: `2px solid ${theme.palette.primary.main}` }}>
        <PublicationManagerSearch
          searchState={searchState}
          onSearch={onSearch}
          publications={searchDocs}
          loading={loading}
        />
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item style={{ fontSize: '20px' }}>
            Találatok: ({publications?.length ?? 0})
          </Grid>
          <Grid item>
            <Grid container item alignItems="center" spacing={2}>
              <Grid item>
                <PublicationsExportButton publications={publications} />
              </Grid>
              <Grid item>
                <Select
                  variant="outlined"
                  margin="dense"
                  value={itemsPerPage}
                  onChange={(e) => onItemsPerPageChange(+e.target.value)}
                >
                  <MenuItem value={10}>10/lap</MenuItem>
                  <MenuItem value={20}>20/lap</MenuItem>
                  <MenuItem value={50}>50/lap</MenuItem>
                  <MenuItem value={100}>100/lap</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <PublicationManagerTable
          page={page}
          itemsPerPage={itemsPerPage}
          onPageChange={onPageChange}
          publications={publications}
          loading={loading}
          orderState={orderState}
          onOrder={onOrder}
        />
      </Grid>
    </Grid>
  );
};

export default PublicationManager;
