import { gql } from '@apollo/client';

export const FETCH_PUBLIC_SUBMISSION_REPORT = gql`
  query publicSubmissionReport {
    submissionReport2023: publicSubmissionReport(key: "submissions_report_2023")
    submissionReport2024: publicSubmissionReport(key: "submissions_report_2024")
  }
`;

export const FETCH_PROTECTED_SUBMISSION_REPORT = gql`
  query protectedSubmissionReport {
    submissionReport2023: protectedSubmissionReport(key: "submissions_report_2023")
    submissionReport2024: protectedSubmissionReport(key: "submissions_report_2024")
  }
`;
