import React, { useMemo } from 'react';
import VirtualizedAutocomplete from '../../../../../Virtualized/VirtualizedAutocomplete/VirtualizedAutocomplete';
import { CircularProgress, TextField } from '@material-ui/core';
import { uniq } from 'lodash';
import { searchParamOption } from '../../../../../../constants/publicationManager';

/**
 * @typedef {{
 *   loading: boolean;
 *   publications: any[];
 *   searchParam: Object;
 * } & Partial<
 *   import('../../../../../Virtualized/VirtualizedAutocomplete/VirtualizedAutocomplete').VirtualizedAutocompleteProps
 * >} AutocompleteInputProps
 */

/**
 * @param {AutocompleteInputProps} props
 * @returns {JSX.Element}
 */
const AutocompleteInput = ({ loading, publications, searchParam, ...props }) => {
  const options = useMemo(() => {
    if (publications && searchParam) {
      const newOptions = uniq(
        publications.reduce((newOptions, publication) => {
          if (publication[searchParam.value]) {
            newOptions.push(publication[searchParam.value]);
          }

          return newOptions;
        }, [])
      ).sort(new Intl.Collator('hu', { ignorePunctuation: true }).compare);

      // Adhoc megoldás, lehetne szebben is, de most nincs rá idő
      if (searchParam === searchParamOption.releaseDate) {
        newOptions.reverse();
      }

      return newOptions;
    }

    return [];
  }, [publications, searchParam]);

  return (
    <VirtualizedAutocomplete
      fullWidth
      disabled={loading}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="dense"
          variant="outlined"
          label="Keresési érték"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="primary" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      {...props}
    />
  );
};

export default AutocompleteInput;
