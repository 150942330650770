import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';

/**
 * @typedef {{ loading: boolean } & Omit<
 *   import('@material-ui/lab').AutocompleteProps,
 *   'renderInput'
 * > &
 *   Partial<Pick<import('@material-ui/lab').AutocompleteProps, 'renderInput'>>} SearchParamInputProps
 */

/**
 * @param {SearchParamInputProps} props
 * @returns {JSX.Element}
 */
const SearchParamInput = ({ loading, ...props }) => (
  <Autocomplete
    fullWidth
    disabled={loading}
    getOptionLabel={(option) => {
      return option.label ?? '';
    }}
    getOptionSelected={(option, value) => option.value === value.value}
    renderInput={(params) => (
      <TextField
        {...params}
        margin="dense"
        variant="outlined"
        label="Keresési paraméter"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="primary" size={20} /> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
      />
    )}
    {...props}
  />
);

export default SearchParamInput;
