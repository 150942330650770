import React from 'react';
import AutocompleteInput from './AutocompleteInput/AutocompleteInput';
import DateRangePickerInput from './DateRangePickerInput/DateRangePickerInput';
import TextFieldInput from './TextFieldInput/TextFieldInput';
import { createFilterOptions } from '@material-ui/lab';

const SearchValueInput = ({ value, onChange, searchParam, publications, loading }) => {
  switch (searchParam?.type) {
    case 'autocomplete':
      return (
        <AutocompleteInput
          inputValue={value ?? ''}
          onInputChange={(event, newValue) => {
            onChange(newValue);
          }}
          loading={loading}
          publications={publications}
          searchParam={searchParam}
          filterOptions={createFilterOptions({ matchFrom: 'start' })}
        />
      );
    case 'dateRangePicker':
      return (
        <DateRangePickerInput
          from={value?.from ?? null}
          to={value?.to ?? null}
          onChange={(from, to) => onChange({ from, to })}
        />
      );
    case 'textField':
      return (
        <TextFieldInput
          value={value ?? ''}
          onChange={(event) => onChange(event.target.value)}
        />
      );
    default:
      return value;
  }
};

export default SearchValueInput;
