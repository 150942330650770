import { TableCell } from '@material-ui/core';
import React from 'react';

/**
 * @param {import('@material-ui/core').TableCellProps} props
 * @returns {JSX.Element}
 */
const BodyCell = ({ ...props }) => (
  <TableCell {...props} style={{ overflowWrap: 'break-word', ...props?.style }} />
);

export default BodyCell;
