import React, { memo } from 'react';
import * as XLSX from 'xlsx';
import { Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import { useReactiveVar } from '@apollo/client';
import { accessTokenVar } from '../../../../apollo/localState/accessTokenVar';

/**
 * @typedef {{ publications: any[] } & import('@material-ui/core').ButtonProps} PublicationsExportButtonProps
 */

/**
 * @param {PublicationsExportButtonProps} props
 * @returns {JSX.Element}
 */
const PublicationsExportButton = ({ publications, ...props }) => {
  const { accessToken } = useReactiveVar(accessTokenVar);
  // const emailColumnVisible = !!accessToken;
  // OKPD-12751
  const emailColumnVisible = true;

  return (
    <Button
      color="secondary"
      startIcon={<GetAppIcon />}
      onClick={() => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(
          publications?.map((publication) => ({
            Cím: publication.title,
            // Alcím: publication.subTitle,
            Szerző: publication.author,
            ISBN: publication.isn,
            'Megjelenés éve': publication.releaseDate,
            Kiadó: publication.partnerName,
            ...(emailColumnVisible && { 'Kiadó e-mail-címe': publication.partnerEmail }),
            'Megjelenés helye (vármegye)': publication.partnerCounty,
            'Megjelenés helye (település)': publication.partnerCity,
            'Beérkezés dátuma': publication.processedAt
              ? moment(publication.processedAt).format('YYYY.MM.DD.')
              : '',
          }))
        );
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Exportált rekordok');
        XLSX.writeFile(workbook, 'Exportált rekordok.xlsx');
      }}
      {...props}
    >
      XLSX
    </Button>
  );
};

export default memo(PublicationsExportButton);
