import { Box, Button, Grid, IconButton, makeStyles } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import React from 'react';
import { searchParamOption } from '../../../../constants/publicationManager';
import SearchParamInput from './SearchParamInput/SearchParamInput';
import SearchValueInput from './SearchValueInput/SearchValueInput';
// import { useReactiveVar } from '@apollo/client';
// import { accessTokenVar } from '../../../../apollo/localState/accessTokenVar';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
      border: '1px solid lightgrey',
      borderRadius: '5px',
    },
  },
}));

const SearchRow = ({
  searchParam,
  setSearchParam,
  searchValue,
  setSearchValue,
  deleteRow,
  publications,
  loading,
}) => {
  // const { accessToken } = useReactiveVar(accessTokenVar);

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container alignItems="center" spacing={1} className="searchRowContainer">
        <Grid item xs={12} md={4} lg={3}>
          <SearchParamInput
            value={searchParam}
            onChange={(event, newValue) => {
              setSearchParam(newValue);
              setSearchValue(null);
            }}
            loading={loading}
            options={Object.values(searchParamOption)}
            // options={Object.values(searchParamOption).filter(
            //   (value) => value !== searchParamOption.partnerEmail || accessToken
            // )}
          />
        </Grid>
        {searchParam && (
          <Grid item xs={12} md lg={4}>
            <SearchValueInput
              value={searchValue}
              onChange={(newValue) => {
                setSearchValue(newValue);
              }}
              searchParam={searchParam}
              publications={publications}
              loading={loading}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md="auto"
          className="deleteRowIcon"
          style={{ textAlign: 'end' }}
        >
          <Box display={{ xs: 'none', md: 'block' }}>
            <IconButton color="secondary" onClick={deleteRow}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box display={{ xs: 'block', md: 'none' }}>
            <Button color="secondary" onClick={deleteRow}>
              Sor törlése
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchRow;
