import sort from 'fast-sort';
import { intersection } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';

export const usePublications = ({ searchDocs, searchIndex, searchState, orderState }) => {
  const publications = useMemo(() => {
    if (searchDocs && searchIndex && searchState) {
      // Options for the search engine
      const searchOptions = searchState.reduce((newOptions, rowState) => {
        if (rowState.searchParam && rowState.searchValue) {
          // Some param types are handled by custom logic separately from the search engine
          if (rowState.searchParam.type !== 'dateRangePicker') {
            newOptions.push({
              field: rowState.searchParam.value,
              query: rowState.normalizedSearchValue ?? rowState.searchValue,
              limit: searchDocs.length,
            });
          }
        }

        return newOptions;
      }, []);

      /**
       * @type {Map<string, boolean>}
       */
      let searchResults;
      if (searchOptions.length > 0) {
        const resultSet = searchIndex.search(searchOptions);
        searchResults = new Map(
          intersection(...resultSet.map((element) => element.result)).map((guid) => [
            guid,
            true,
          ])
        );
      } else {
        // FlexSearch does not support searching without options, so the input data is passed as the search results instead
        searchResults = new Map(searchDocs.map((doc) => [doc.guid, true]));
      }

      const newPublications = searchDocs.filter((doc) => {
        // Filter using the search engine
        if (!searchResults.has(doc.guid)) {
          return false;
        }

        // Custom filters
        const dateRangePickerSearchRows = searchState.filter(
          (searchRow) => searchRow.searchParam?.type === 'dateRangePicker'
        );

        const isDocDateWithinAllIntervals =
          dateRangePickerSearchRows.length === 0 ||
          dateRangePickerSearchRows.every((searchRow) => {
            const intervalFrom = searchRow.searchValue?.from;
            const intervalTo = searchRow.searchValue?.to;
            const docDate = moment(doc[searchRow.searchParam?.value]);
            const isDocDateWithinInterval =
              (!intervalFrom || moment(docDate).isSameOrAfter(intervalFrom)) &&
              (!intervalTo || moment(docDate).isSameOrBefore(intervalTo));

            return isDocDateWithinInterval;
          });

        return isDocDateWithinAllIntervals;
      });

      return newPublications;
    }

    return searchDocs;
  }, [searchDocs, searchIndex, searchState]);

  const orderedPublications = useMemo(
    () =>
      sort(publications).by({
        [orderState.order]: (publication) => publication[orderState.orderBy],
        comparer: new Intl.Collator('hu', { ignorePunctuation: true }).compare,
      }),
    [publications, orderState]
  );

  return { publications: orderedPublications };
};
