import { TableCell, TableSortLabel, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  tableSortLabel: {
    color: 'inherit !important',
    '& svg': {
      color: 'inherit !important',
    },
  },
});

const HeaderCell = ({ id, orderState, onOrder, children, ...props }) => {
  const { orderBy, order } = orderState;

  const classes = useStyles();

  return (
    <TableCell sortDirection={orderBy === id ? order : false} {...props}>
      <TableSortLabel
        active={orderBy === id}
        direction={orderBy === id ? order : 'asc'}
        onClick={() => onOrder(id)}
        className={classes.tableSortLabel}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  );
};

export default HeaderCell;
