import { Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import React, { createContext, forwardRef, useContext, useEffect, useRef } from 'react';
import { VariableSizeList } from 'react-window';

const Row = ({ data, index, style }) =>
  React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + 8,
    },
  });

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

const ListboxComponent = forwardRef(({ children, ...other }, ref) => {
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 36;
  const getHeight = () => Math.min(itemCount, 10) * itemSize;
  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * 10}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {Row}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const useStyles = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

/**
 * @typedef {import('@material-ui/lab').AutocompleteProps} VirtualizedAutocompleteProps
 */

/**
 * @param {VirtualizedAutocompleteProps} props
 */
const VirtualizedAutocomplete = (props) => {
  const classes = useStyles();

  return (
    <Autocomplete
      classes={classes}
      ListboxComponent={ListboxComponent}
      renderOption={(option) => <Typography noWrap>{option}</Typography>}
      {...props}
    />
  );
};

export default VirtualizedAutocomplete;
