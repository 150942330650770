import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styles from './index.module.scss';
import BasicLayout from '../layouts/basic_layout';
import Head from '../components/head';
import BreadcrumbsLayout from '../layouts/breadcrumbs_layout';
import PublicationManager from '../components/PublicationManager/PublicationManager';
import { controlledFeatures } from '../utils/featureControl';

const Kotelespeldanyok = (props) => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { title: { eq: "Kötelespéldány-szolgáltatás" } }) {
        frontmatter {
          title
          mainpage
        }
        html
      }
    }
  `);

  return (
    <React.Fragment>
      <BasicLayout>
        <Head title={data.markdownRemark.frontmatter.title} />
        <BreadcrumbsLayout
          menuItems={
            controlledFeatures.homepagePath.includes('kotelespeldanyok')
              ? []
              : [
                  { name: data.markdownRemark.frontmatter.mainpage, path: '/' },
                  { name: 'Kötelespéldány-szolgáltatás' },
                ]
          }
        >
          <div className={styles.textContentContainer}>
            <h3>Kötelespéldány-szolgáltatás</h3>
            <PublicationManager />
          </div>
        </BreadcrumbsLayout>
      </BasicLayout>
    </React.Fragment>
  );
};

export default Kotelespeldanyok;
