import { TextField } from '@material-ui/core';
import React from 'react';

/**
 * @typedef {import('@material-ui/core').TextFieldProps} TextFieldInputProps
 */

/**
 * @param {TextFieldInputProps} props
 * @returns {JSX.Element}
 */
const TextFieldInput = ({ ...props }) => (
  <TextField
    fullWidth
    margin="dense"
    variant="outlined"
    label="Keresési érték"
    {...props}
  />
);

export default TextFieldInput;
