import { Grid } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React from 'react';

/**
 * @typedef {{
 *   from: import('@material-ui/pickers').KeyboardDatePickerProps['value'];
 *   to: import('@material-ui/pickers').KeyboardDatePickerProps['value'];
 *   onChange: (
 *     from: import('@material-ui/pickers').KeyboardDatePickerProps['value'],
 *     to: import('@material-ui/pickers').KeyboardDatePickerProps['value']
 *   ) => void;
 *   FromProps?: import('@material-ui/pickers').KeyboardDatePickerProps;
 *   ToProps?: import('@material-ui/pickers').KeyboardDatePickerProps;
 * }} DateRangePickerInputProps
 */

/**
 * @param {DateRangePickerInputProps} props
 * @returns {JSX.Element}
 */
const DateRangePickerInput = ({ from, to, onChange, FromProps, ToProps }) => (
  <Grid container spacing={1}>
    <Grid item xs={12} sm={6}>
      <KeyboardDatePicker
        label="Időszak kezdete"
        value={from}
        onChange={(newValue) => {
          onChange(newValue ? moment(newValue).startOf('day').toDate() : null, to);
        }}
        // onChange nem hívódik meg ha manuálisan írsz be dátumot
        onBlur={(e) => {
          onChange(
            e.target.value ? moment(e.target.value).startOf('day').toDate() : null,
            to
          );
        }}
        fullWidth
        autoOk={true}
        variant="inline"
        inputVariant="outlined"
        margin="dense"
        maxDate={new Date()}
        // minDate: new Date('1900-01-01'),
        error={false}
        helperText={null}
        format="yyyy.MM.dd."
        {...FromProps}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <KeyboardDatePicker
        label="Időszak vége"
        value={to}
        onChange={(newValue) => {
          onChange(from, newValue ? moment(newValue).endOf('day').toDate() : null);
        }}
        // onChange nem hívódik meg ha manuálisan írsz be dátumot
        onBlur={(e) => {
          onChange(
            from,
            e.target.value ? moment(e.target.value).endOf('day').toDate() : null
          );
        }}
        fullWidth
        autoOk={true}
        variant="inline"
        inputVariant="outlined"
        margin="dense"
        maxDate={new Date()}
        // minDate: new Date('1900-01-01'),
        error={false}
        helperText={null}
        format="yyyy.MM.dd."
        {...ToProps}
      />
    </Grid>
  </Grid>
);

export default DateRangePickerInput;
